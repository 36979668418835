































































































































































































import {mixins} from "vue-class-component";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import {Component, Prop, Watch} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {AUTH_STORE_NAME, AuthStoreActions, AuthStoreGetters,} from "@/store/auth.store";
import User from "@/models/User.model";
import {UserRoles} from "@/enum/UserRoles.enum";

const AuthStore = namespace(AUTH_STORE_NAME);

@Component({
  components: {
    CreateNewUserComponent: () =>
      import("@/components/Create.new.user.component.vue"),
    EditNewUserComponent: () =>
      import("@/components/Edit.new.user.component.vue"),
    DeleteUserComponent: () => import("@/components/Delete.user.component.vue"),
    RequestNewPasswordComponent: () =>
      import("@/components/Request.new.password.component.vue"),
  },
})
export default class EmployeeServiceCenterComponent extends mixins(
  ErrorMessageHandlerMixin
) {
  @Prop({ default: true })
  public value!: number;

  @AuthStore.Action(AuthStoreActions.GET_USER_BY_ROLE)
  private getUserByRole!: (payload: { role: UserRoles }) => Promise<any | null>;

  @AuthStore.Getter(AuthStoreGetters.ROLED_USER)
  private roledUser!: User[];

  private user: User = User.parseFromObject({});

  private showCreateNewUserModal = false;

  private showEditNewUserModal = false;

  private showRequestNewPasswordModal = false;

  private showDeleteUserModal = false;

  private loading: boolean = false;

  public async created() {
    try{
      this.loading = true;
      await this.getUserByRole({ role: UserRoles.ADMIN });
    } catch (e) {

    }finally {
      this.loading = false;
    }

  }

  private onHideDeleteUserModal() {
    this.showDeleteUserModal = false;
  }

  private onShowDeleteUserModal(user: User) {
    this.user = user;
    this.showDeleteUserModal = true;
  }

  private onHideCreateNewUserModal() {
    this.showCreateNewUserModal = false;
  }

  private onShowCreateNewUserModal() {
    this.showCreateNewUserModal = true;
  }

  private onHideRequestNewPasswordModal() {
    this.showRequestNewPasswordModal = false;
  }

  private onShowRequestNewPasswordModal(user: User) {
    this.user = user;
    this.showRequestNewPasswordModal = true;
  }

  private onHideEditNewUserModal() {
    this.showEditNewUserModal = false;
  }

  private onShowEditNewUserModal(user: User) {
    this.user = user;
    this.showEditNewUserModal = true;
  }

  private roleColor(user: User) {
    if (user.role === UserRoles.ADMIN) {
      return "#0081F9";
    } else {
      return "#005089";
    }
  }

  private filterSearch(value: string, search: string, item: any){

    var name = item.firstName + " " + item.lastName;
    if(this.search_temp==="" && (item.role === this.enabled || this.enabled === UserRoles.ALL) ){
      return true;
    }else if((name.toUpperCase().includes(this.search_temp.toUpperCase()))
        && (item.role === this.enabled || this.enabled === UserRoles.ALL) ){
      return true;
    }else{
      return false;
    }
  }

  @Watch('search_temp', { immediate: true })
  private filterOnSelect() {
    this.search = this.$t('EMPLOYEES.' + this.enabled).toString();
  }

  private filterOnType() {
    this.search = this.search_temp;
  }

  private headers: any = [
    {
      text: this.$t("GENERAL.FIRST_NAME"),
      align: "start",
      value: "firstName",
      width: "25%",
    },
    {
      text: this.$t("GENERAL.LAST_NAME"),
      align: "start",
      value: "lastName",
      width: "25%",
    },
    {
      text: this.$t("GENERAL.EMAIL"),
      align: "start",
      value: "email",
      width: "25%",
    },
    {
      text: this.$t("GENERAL.ROLE"),
      align: "start",
      value: "role",
      width: "25%",
    },
  ];

  private search: string = "";

  private search_temp: string = "";

  private enabled: string = UserRoles.ALL;

  private slots: any = [
      UserRoles.ALL,
      UserRoles.ADMIN,
      UserRoles.EMPLOYEE
  ];
}
